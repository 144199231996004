<template>
  <div id="main-wrapper">

    <div class="main-content is-footer">

      <!--7گزینه-->
      <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
      <vcl-Table  v-if="!pageloading" ></vcl-Table>
      <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>

      <v-container v-else >
        <v-row>
          <p class="text-center font-medium font-weight-bold pt-3"><v-icon color="black" size="37">mdi-seal</v-icon> مسابقات فعال موسسه </p>
        </v-row>


        <v-card  :key="key" v-for="(item,key) in games"
                 class="mx-auto"
                 max-width="344"
        >
          <v-img
              :src="url+'uploads/games/'+item.noe+'.jpg'"
              height="200px"
          ></v-img>


          <v-card-actions >
            <v-btn
                @click="show = !show"
                color="orange lighten-2"
                text
            >
              <div class="middle" >
                <v-btn @click="$router.push({path:'/game/'+item.noe});" :loading="loading" depressed :color="randomColor()"  height="50" style="width: 100%"> برای شرکت در مسابقه کلیک کنید </v-btn></div>
            </v-btn>

            <v-btn
                icon
                @click="show = !show"
            >
              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text style="text-align: justify;">
                {{ item.saatmohlat.replace(/(<([^>]+)>)/gi, "").replace(/&.*;/g,"") }} {{(item.dore)}}
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>

      </v-container>




    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src


import axios from "axios";
import {mapGetters} from 'vuex'
import appHeader from '../views/_header'
import appFooter from '../views/_footer'
import { VclFacebook, VclCode,VclTwitch,VclTable } from 'vue-content-loading';

export default {
  name: 'gamest',
  components: {
    appHeader,
    appFooter,
    VclFacebook,
    VclCode,
    VclTable,
    VclTwitch,
  },
  data () {
    return {
      show: true,
      games:[],
      url:'http://g.samanyaar.ir/soccerp/',
      pageloading: false,
      loading: false,
    }
  },
  mounted() {
    let self = this;
    self.getteam();

  },
  computed:{
    ...mapGetters(['baseUrl',"auth"]),
    totalCart(){
      let customerPrice=0,
          //realPrice =0,
          couponPrice=0,
          taxPrice=0;
      this.$store.state.cart.forEach(function (item,key) {
        //realPrice+=parseInt(item.stock.real_price);
        customerPrice+=parseInt(item.customer_price) * parseInt(item.count);
      });

      couponPrice = customerPrice * this.coupon_percent;
      taxPrice = (customerPrice - couponPrice) * this.tax;

      return {
        //realPrice:realPrice,
        customerPrice:customerPrice,
        taxPrice:taxPrice,
        couponPrice:couponPrice,

        paidPrice:customerPrice - couponPrice + taxPrice,
      }
    },
  },
  methods:{
    randomColor() {
      const r = () => Math.floor(250 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },
    getteam(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()

      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);



       axios.post(this.baseUrl+'/teamfaal.aspx', formData, config)
                .then(function (r) {
                 // console.log(r.data);
                  if(r.data.result==1){
                    self.games=r.data.data;
                    if (r.data.data.length==0)
                    {
                      self.$router.push({path:'/'});
                      self.helperToast({
                        show:true,
                        text:'مسابقه فعالی وجود ندارد',
                        color:'blue'
                      });
                    }


                    self.pageloading=true;

                  }
                  else
                  {self.helperToast({
                    show:true,
                    text:r.data.message,
                    color:'red'
                  });
                    self.$router.push({ path: '/' });
                  }
                  self.loading = false;
                });
    },


  },
  watch:{}
}
</script>

<style>
  .product-slider .v-btn--active{
    background: transparent
  }
  .product-slider .v-progress-linear {
    height: 2px  !important;
  }
  .add-to-cart{
    height: 80%;
    background: #e91e63 !important;
    color:#fff;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 13px;
  }
  .product-property{
    margin: 0;
    padding: 0;

  }
  .middle {
    display: table-cell;
    vertical-align: middle;
  }
  .product-property li{
    list-style: none;
    display: inline-block;
    width: 100%;
  }
  .product-property li > .product-param-list,
  .product-property li > .product-value-list{
      /* background: #f6f6f6; */
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 7px 10px 7px 7px;
      margin-bottom: 15px;
      display: inline-block;
      border-bottom: solid 1px #ddd;
  }
  .product-property li > .product-param-list{
    width: 35%;
    margin-left: 5%;
    font-size: 13px;
    color:black;
    vertical-align: top;
    border-bottom: none;
  }
  .product-property li > .product-value-list{
    width: 60%;
    font-size: 12px;
  }
  .product-property li:last-child .product-value-list{
    border-bottom: none;
  }
  .last-comment{
    border-color: #ddd;
    border:solid 1px #ddd !important;
  }
</style>
